<template>
	<div class="main">
		<div class="perf-info-box">
			<div class="table-box">
				<div class="table-title-line">
					<div class="table-cell" style="width: 30vw">序号</div>
					<div class="table-line-icon"></div>
					<div class="table-cell2" style="width: 33vw">
						<van-dropdown-menu active-color="#1989fa">
							<van-dropdown-item @change="searchTime"  v-model="postData.searchType" :options="option1" />
						</van-dropdown-menu>
					</div>
					<div class="table-line-icon"></div>
					<div class="table-cell" style="width: 35vw">最终得分</div>
				</div>
				<template  v-if="list">
					<div @click="toAdd(item)" v-for="(item, index) in list" :key="index" class="table-line">
						<div class="table-cell" style="width: 30vw">{{index + 1}}</div>
						<div class="table-cell" style="width: 35vw">{{item.years}}</div>
						<div class="table-cell" style="width: 35vw">{{item.finalScore}}</div>
					</div>
				</template>
				<template v-else>
					<empty description="暂无数据" />
				</template>
			</div>
		</div>
		<div class="page-box">
			<van-pagination @change="changePage()" v-model="postData.page" :total-items="total" :items-per-page="20" />
		</div>
	</div>
</template>

<script>
	import { myAppraisal } from '@/api/performance'
	// import {Dialog, Toast} from 'vant';
	import {Empty} from 'vant';
	export default {
		name: 'DemandList',
		components: {
			Empty
		},
		data() {
			return {
				value1: 0,
				option1: [
					{ text: '全部', value: 4 },
					{ text: '本月', value: 0 },
					{ text: '本季度', value: 1 },
					{ text: '本年', value: 2 },
				],
				list: '',
				postData: {
					page: 1,
					pageSize: 20,
					searchType: 4
				},
				total: '',
				type: '' // 类型 1：任务审核列表，2 评分列表
			}
		},
		created() {
			const query = this.$route.query
			// 判断是否登录
			if(!sessionStorage.getItem("token")){
				//存储路由
				sessionStorage.setItem("perfPath", this.$route.path)
				sessionStorage.setItem("perfQuery", JSON.stringify(query))
				const postData = {}
				this.$router.push({ path: '/perfLogin', query: postData})
				return
			}
			this.type = query.type
			this.getInfo()
		},
		mounted:function(){
			// 清除之前的缓存
			sessionStorage.removeItem('approvalData')
			sessionStorage.removeItem('prefData')
		},
		methods: {
			// 分页切换
			changePage(){
				this.getInfo()
			},
			// 获取信息
			getInfo(){
				myAppraisal(this.postData).then((res) => {
					this.list = res.data.records;
					this.total = res.data.total;
				})
			},
			// 按时间搜索
			searchTime(){
				this.postData.page = 1
				this.getInfo()
			},
			// 调到新增页面
			toAdd(row){
				// 状态：0月初暂存 1月初待审批 2月初通过 3月初驳回 4月初完成
				if(row.status === -1){
					const postData = {}
					postData.id = row.id
					postData.month = row.years //
					this.$router.push({ path: '/performance', query: postData})
				}else if(row.status === 1){
					const postData = {}
					postData.id = row.id
					this.$router.push({ path: '/showIndex', query: postData})
				}else if(row.status === 2) {
					const postData = {}
					postData.id = row.id
					this.$router.push({ path: '/showIndex', query: postData})
				}else if(row.status === 3) {
					const postData = {}
					postData.id = row.id
					postData.month = row.years
					this.$router.push({ path: '/performance', query: postData})
				}else if(row.status === 4) {
					const postData = {}
					postData.id = row.id
					this.$router.push({ path: '/appIndex', query: postData})
				}
			},
		}
	}
</script>

<style scoped>
	.main{
		color:#333333;
		font-size: 15px;
	}
	.perf-info-box{
		background: #FFFFFF;
		border-radius: 8px;
	}
	/*表格开始*/
	.table-box{
		width: calc(100vw - 2px);
		border-radius: 1px;
	}
	.table-line-icon{
		width: 1px;
		height: 100%;
		background: linear-gradient(180deg, rgba(255,255,255,0) 0%, #DDDDDD 53%, rgba(255,255,255,0) 100%);
		border-radius: 2px;
	}
	.table-title-line{
		width: 100%;
		display: flex;
		justify-content: space-between;
		background-color: #ffffff;
		color: #666666;
		height: 42px;
		line-height: 42px;
		font-weight: bold;
		text-align: center;
	}
	.table-line{
		width: 100%;
		display: flex;
		justify-content: space-between;
		color: #000000;
		height: 42px;
		line-height: 42px;
		text-align: center;
	}
	.table-cell{
		border-bottom: 1px solid #F6F6F6;
		overflow:hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow:ellipsis;
		padding: 0 5px;
	}
	.table-cell2{
		border-bottom: 1px solid #F6F6F6;
		overflow:hidden;
		white-space: nowrap;
		text-overflow: ellipsis;
		-o-text-overflow:ellipsis;
	}
	/*表格结束*/
	.page-box{
		position: fixed;
		bottom: 0;
		left: 0;
		width: 100vw;
	}
</style>
